'use client'

import { ReactNode, useEffect } from 'react'
import { useIntl } from 'react-intl'
import {
  Text,
  Dialog,
  Button,
  Navigation,
  Cell,
  Image,
  Divider,
  Spacer,
  Icon,
  InfoBanner,
} from '@vinted/web-ui'
import { InfoCircle16, X24 } from '@vinted/monochrome-icons'

import useAsset from 'hooks/useAsset'
import useTranslate from 'hooks/useTranslate'
import useAbTest from 'hooks/useAbTest'
import useSession from 'hooks/useSession'

import ContentLoader from 'components/ContentLoader'
import ScrollableArea from 'components/ScrollableArea'
import ErrorState from 'components/ErrorState'

import { ClosetPromotionPricingModel } from 'types/models'
import { formatCurrencyAmount } from 'libs/utils/formatString'
import { reloadPage } from 'libs/utils/window'
import { ResponseError } from 'types/api'
import { exposeVasBannerLock } from 'data/api'
import { Screen } from 'constants/tracking/screens'

type Props = {
  pricing: ClosetPromotionPricingModel | undefined
  isPricingLoading: boolean
  isOrderLoading: boolean
  error: ResponseError | undefined
  show: boolean
  onNextAction: () => void
  onPreview: () => void
  onBack: () => void
  onDynamicPricingInfo: () => void
}

const ClosetPromotionPreCheckoutModal = ({
  pricing,
  isPricingLoading,
  isOrderLoading,
  error,
  show,
  onNextAction,
  onPreview,
  onBack,
  onDynamicPricingInfo,
}: Props) => {
  const translate = useTranslate('closet_promotion.pre_checkout_modal')
  const a11yTranslate = useTranslate('closet_promotion.a11y.actions')
  const { locale } = useIntl()
  const vasBannerLockAbTest = useAbTest({ abTestName: 'vas_banner_lock' })
  const { screen } = useSession()

  const asset = useAsset('/assets/closet-promotion')

  useEffect(() => {
    if (!vasBannerLockAbTest?.variant) return
    if (!pricing) return
    if (screen !== Screen.UserProfile) return

    exposeVasBannerLock()
  }, [vasBannerLockAbTest, pricing, screen])

  function renderNavigation() {
    return (
      <Navigation
        right={
          <Button
            iconName={X24}
            theme="amplified"
            styling={Button.Styling.Flat}
            testId="closet-promotion-pre-checkout-back-button"
            onClick={onBack}
            aria={{ 'aria-label': a11yTranslate('close') }}
          />
        }
        body={
          <Text
            type={Text.Type.Title}
            width={Text.Width.Parent}
            alignment={Text.Alignment.Center}
            text={translate('title')}
          />
        }
      />
    )
  }

  function renderIntro() {
    const values = {
      'learn-more': (chunks: Array<ReactNode>) => (
        <button onClick={onPreview} type="button">
          <Text theme="primary" text={chunks} underline />
        </button>
      ),
    }

    return (
      <>
        <Cell>
          <Text text={translate('intro.title')} type={Text.Type.Heading} />
        </Cell>
        <Cell
          title={translate('intro.visibility.title')}
          body={translate('intro.visibility.body')}
          prefix={
            <Image
              src={asset('avatars-group.svg', { theme: { dark: 'dark/avatars-group.svg' } })}
              size={Image.Size.Large}
              alt=""
            />
          }
        />
        <Cell
          title={translate('intro.relevancy.title')}
          body={translate('intro.relevancy.body', values)}
          prefix={
            <Image
              src={asset('hand-ok.svg', { theme: { dark: 'dark/hand-ok.svg' } })}
              size={Image.Size.Large}
              alt=""
            />
          }
        />
        <Cell
          title={translate('intro.insights.title')}
          body={translate('intro.insights.body')}
          prefix={
            <Image
              src={asset('chart.svg', { theme: { dark: 'dark/chart.svg' } })}
              size={Image.Size.Large}
              alt=""
            />
          }
        />
      </>
    )
  }

  function renderPrice() {
    if (!pricing) return null

    const { isDynamicPricing, totalPrice, discountedPrice, isSalesTaxApplicable } = pricing

    const formattedTotalPrice = formatCurrencyAmount(totalPrice, locale)
    const formattedDiscountedPrice = formatCurrencyAmount(discountedPrice, locale)

    const showTotalPrice = discountedPrice.amount < totalPrice.amount
    const suffix = isDynamicPricing ? (
      <div className="u-flexbox">
        <Icon name={InfoCircle16} color={Icon.Color.GreyscaleLevel4} />
      </div>
    ) : null

    return (
      <>
        <Cell
          styling={Cell.Styling.Tight}
          suffix={suffix}
          onClick={isDynamicPricing ? onDynamicPricingInfo : undefined}
          clickable={isDynamicPricing}
          testId="closet-promotion-dynamic-pricing-info"
        >
          {showTotalPrice && <Text text={formattedTotalPrice} strikethrough />}
          <Spacer size={Spacer.Size.Small} orientation={Spacer.Orientation.Vertical} />
          <Text text={formattedDiscountedPrice} type={Text.Type.Title} />
        </Cell>
        {isSalesTaxApplicable && (
          <div>
            <Text
              theme="muted"
              text={translate('sales_tax_note')}
              testId="closet-promotion-pre-checkout-modal--sales-tax-note"
            />
          </div>
        )}
      </>
    )
  }

  function renderDeal() {
    if (!pricing) return null

    const { pricePerDay, effectiveDays } = pricing

    const formattedPricePerDay = formatCurrencyAmount(pricePerDay, locale)

    return (
      <Cell
        title={translate('duration_day_count', { count: effectiveDays }, { count: effectiveDays })}
        body={translate('price_per_day', { price: formattedPricePerDay })}
        suffix={renderPrice()}
      />
    )
  }

  function renderConfirmationAction() {
    return (
      <div className="u-ui-margin-horizontal-large u-ui-margin-bottom-large">
        <Button
          text={translate('actions.proceed')}
          styling={Button.Styling.Filled}
          onClick={onNextAction}
          isLoading={isOrderLoading}
          testId="closet-promotion-pre-checkout--proceed-button"
        />
      </div>
    )
  }

  function renderLockedInfoBanner() {
    return (
      <div className="u-ui-margin-horizontal-large u-ui-margin-bottom-large">
        <InfoBanner
          body={pricing?.lockedNote}
          styling={InfoBanner.Styling.Tight}
          testId="locked-banner"
        />
      </div>
    )
  }

  function renderContent() {
    if (error) return <ErrorState onCtaClick={reloadPage} />
    if (isPricingLoading || !pricing) {
      return <ContentLoader testId="pre-checkout-loader" styling={ContentLoader.Styling.Wide} />
    }

    return (
      <>
        <Image
          src={asset('closet-promotion-onboarding.png', {
            theme: { dark: 'dark/closet-promotion-onboarding.png' },
          })}
          alt=""
        />
        {renderIntro()}
        <Divider />
        {renderDeal()}
        {pricing.locked && renderLockedInfoBanner()}
        {!pricing.locked && renderConfirmationAction()}
      </>
    )
  }

  return (
    <Dialog show={show} hasScrollableContent testId="closet-promotion-pre-checkout-modal">
      <div className="u-fill-width">
        <ScrollableArea>
          {renderNavigation()}
          {renderContent()}
        </ScrollableArea>
      </div>
    </Dialog>
  )
}

export default ClosetPromotionPreCheckoutModal
